import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import { isEmpty, get } from 'lodash'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useSnackbar } from 'notistack'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Grid,
  Box,
  CardHeader,
  CardContent,
  Card,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { gtmEvent } from 'lib/gtm'
import { dollarsToCents, centsToDollars } from 'lib/utils/number'
import useIconAccessor from 'components/lesson-type-category/icons'
import BookingTypeFormSuccessDialog from './booking-type-form-success-dialog'
import useStyles from './styles'
import Dialog from 'components/dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import { expirationDurationOptions } from 'pages/pga-coach/bookings/booking-types/components/expiration-duration-dropdown'
import SingleOrLessonPack from 'pages/pga-coach/bookings/booking-types/components/single-or-lesson-packs'
import BookingTypeDetails from 'pages/pga-coach/bookings/booking-types/components/booking-type-details'
import BookingTypePaymentOptions from 'pages/pga-coach/bookings/booking-types/components/booking-type-payment-options'
import BookingTypeAdditionalOptions from 'pages/pga-coach/bookings/booking-types/components/booking-type-additional-options'
import { inputPriceRegExp } from 'utils/regExp'
import { PageHeader } from 'components/page-header'
import { FormActionBar } from 'components/form-util'

const CREATE_LESSON_TYPE = gql`
  mutation CreateLessonType($input: CreateLessonTypeInput!) {
    createLessonType(input: $input) {
      success
      message
      lessonType {
        id
        slug
        accessCode
        academy {
          id
        }
        coach {
          externalId
          coachProfile {
            slug
          }
        }
        facilities {
          id
        }
      }
    }
  }
`

const UPDATE_LESSON_TYPE = gql`
  mutation UpdateLessonType($input: UpdateLessonTypeInput!) {
    updateLessonType(input: $input) {
      success
      message
      lessonType {
        slug
        coach {
          externalId
          coachProfile {
            slug
          }
        }
        academy {
          id
        }
      }
    }
  }
`

const DELETE_LESSON_TYPE = gql`
  mutation DeleteLessonType($id: ID!) {
    deleteLessonType(id: $id) {
      success
      message
    }
  }
`

const durationInMinutesOptions = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '50 minutes', value: 50 },
  { label: '60 minutes', value: 60 },
  { label: '90 minutes', value: 90 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 },
  { label: '4 hours', value: 240 },
  { label: '5 hours', value: 300 },
  { label: '6 hours', value: 360 },
  { label: '7 hours', value: 420 },
  { label: '8 hours', value: 480 },
  { label: '9 hours', value: 540 },
]

const validationSchema = (
  categories,
  coachFacilities,
  coachingCenters,
  paymentAccounts,
) =>
  yup.object().shape({
    isLessonPacks: yup.boolean().required(),
    category: yup.string().oneOf(categories.map(bc => bc.value)),
    title: yup.string().required(),
    location: yup
      .string()
      .required()
      .when('isPrivateBookingEnabled', (isPrivateBookingEnabled, field) => {
        isPrivateBookingEnabled
          ? field.oneOf([
            ...coachFacilities.map(cf => cf.id),
            ...coachingCenters.map(cc => cc.id),
          ])
          : field.oneOf(coachFacilities.map(cf => cf.id))
      }),
    coachingCenter: yup.string(),
    durationInMinutes: yup
      .number()
      .oneOf(durationInMinutesOptions.map(dim => dim.value))
      .required(),
    expirationDuration: yup
      .string()
      .oneOf(expirationDurationOptions.map(dim => dim.value)),
    price: yup.string().matches(inputPriceRegExp, 'Please enter a valid price'),
    paymentAccountId: yup
      .string()
      .oneOf(['', ...paymentAccounts.map(pa => pa.id)])
      .nullable()
      .when('isLessonPacks', (isLessonPacks, field) =>
        isLessonPacks ? field.required() : field,
      ),
    description: yup.string(),
    confirmationMessage: yup.string(),
    lessonPacks: yup.array().when('isLessonPacks', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          quantity: yup
            .string()
            .matches(/^[1-9]([0-9]+)?$/, 'Please enter a valid quantity')
            .label('Quantity')
            .required(),
          priceInCents: yup
            .string()
            .matches(inputPriceRegExp, 'Please enter a valid price')
            .label('Price')
            .required('Please enter a valid price'),
        }),
      ),
    }),
    accessCode: yup.string().matches(/^[a-z0-9]+$/i, {
      message: 'Codes must consist only of numbers and letters with no spaces',
      excludeEmptyString: true.valueOf,
    }),
  })

const onSave = () => {
  // GTM Tracking Code
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'bookings',
    formAction: 'update-booking-type',
    formStatus: 'success',
  })
}

const onCreate = user => {
  // GTM Tracking Code
  if (user.onboarding) {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'bookings',
      formAction: 'create-onboarding-booking-type',
      formStatus: 'success',
    })
  } else {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'bookings',
      formAction: 'create-booking-type',
      formStatus: 'success',
    })
  }
}

const onDelete = () => {
  // GTM Tracking Code
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'bookings',
    formAction: 'delete-booking-type',
    formStatus: 'success',
  })
}

const BookingTypeForm = ({
  bookingType,
  categories,
  coachFacilities,
  coachAvailabilities,
  coachingCenters,
  paymentAccounts,
  startTimeIncrementOptions,
  onSuccess,
  progress,
  showOnboardingModal,
  isOnboarding,
}) => {
  const { user } = useAuth()
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
  const [lessonTypeSuccessResult, setLessonTypeSuccessResult] = useState(null)
  const [status, setStatus] = useState(bookingType?.status || 'ACTIVE')
  const singleLessonPrice = bookingType && bookingType.priceInCents

  const [isLessonPacks, setIsLessonPacks] = useState(
    bookingType?.lessonPacks?.length > 0,
  )

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const accessor = useIconAccessor()

  const initialValues =
    bookingType != null
      ? {
        ...bookingType,
        location: get(bookingType, 'locations[0].id'),
        coachingCenter: get(bookingType, 'coachingCenter.id' || null),
        price: centsToDollars(bookingType.priceInCents),
        paymentAccountId: bookingType?.paymentAccount?.id || '',
        expirationDuration:
          bookingType?.lessonPacks[0]?.expirationDuration ||
          expirationDurationOptions.find(opt => opt.default).value,
      }
      : {
        category: '',
        location: '',
        paymentAccountId: '',
        lessonPacks: [
          {
            quantity: '',
            pricePerLesson: '',
            priceInCents: '',
          },
        ],
        expirationDuration: expirationDurationOptions.find(opt => opt.default)
          .value,
      }

  const defaultValues = {
    ...initialValues,
  }

  const [
    createLessonType,
    { loading: isCreateLessonTypeSubmitting },
  ] = useMutation(CREATE_LESSON_TYPE, {
    onCompleted: data => {
      if (data.createLessonType.success) {
        enqueueSnackbar(data.createLessonType.message, { variant: 'success' })
        setLessonTypeSuccessResult(data.createLessonType.lessonType)
        setIsSuccessDialogOpen(true)
        onCreate(user)
      } else {
        enqueueSnackbar(data.createLessonType.message, { variant: 'error' })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['booking-types'],
        })
      enqueueSnackbar('An error has occurred. Please try again later.', {
        variant: 'error',
      })
    },
  })
  const [
    updateLessonType,
    { loading: isUpdateLessonTypeSubmitting },
  ] = useMutation(UPDATE_LESSON_TYPE, {
    onCompleted: data => {
      if (data.updateLessonType.success) {
        enqueueSnackbar(data.updateLessonType.message, { variant: 'success' })
        setLessonTypeSuccessResult(data.updateLessonType.lessonType)
        onSave()
        history.push('/pga-coach/bookings/booking-types')
      } else {
        enqueueSnackbar(data.updateLessonType.message, { variant: 'error' })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['booking-types'],
        })
      enqueueSnackbar('An error has occurred. Please try again later.', {
        variant: 'error',
      })
    },
  })

  const [deleteLessonType] = useMutation(DELETE_LESSON_TYPE, {
    onCompleted: data => {
      if (data.deleteLessonType.success) {
        enqueueSnackbar('Booking Type has been successfully deleted.', {
          variant: 'success',
        })
        onDelete()
        history.push('/pga-coach/bookings/booking-types')
      } else {
        enqueueSnackbar(data.deleteLessonType.message, { variant: 'error' })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['booking-types'],
        })
      enqueueSnackbar('An error has occurred. Please try again later.', {
        variant: 'error',
      })
    },
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(
      validationSchema(
        categories,
        coachFacilities,
        coachingCenters,
        paymentAccounts,
      ),
    ),
  })
  const { register, handleSubmit, errors, control, setValue, formState } = form
  const { touched } = formState
  const hasErrors = Object.keys(errors).length > 0

  const handleCategoryChange = e => {
    const suggestedName = accessor[e.target.value].suggestedName
    if (suggestedName != null) {
      setValue('title', suggestedName, {
        shouldDirty: true,
      })
    }
  }

  const onSuccessDialogClose = () => {
    onSuccess && onSuccess()
    setIsSuccessDialogOpen(false)
    history.push('/pga-coach/bookings/booking-types')
  }

  const onSuccessDialogCreateAnother = () => {
    onSuccess && onSuccess()
    setIsSuccessDialogOpen(false)
    history.push('/pga-coach/bookings/booking-types/new')
  }

  const onDeleteBookingType = () => {
    deleteLessonType({
      variables: {
        id: bookingType.id,
      },
    })
  }

  const onSubmit = data => {
    const input = {
      title: data.title,
      category: data.category,
      description: data.description,
      confirmationMessage: data.confirmationMessage,
      durationInMinutes: data.durationInMinutes,
      locationIds: [data.location],
      ...(bookingType == null && {
        academyId: data.coachingCenter || null,
      }),
      ...(isLessonPacks
        ? {
          lessonPacks: data.lessonPacks.map(lp => ({
            quantity: +lp.quantity,
            priceInCents: dollarsToCents(lp.priceInCents),
            expirationDuration:
              data.expirationDuration === 'never'
                ? null
                : data.expirationDuration,
          })),
        }
        : {
          priceInCents: dollarsToCents(data.price),
        }),
      ...{
        paymentAccountId:
          (data.paymentAccountId || '').length > 0
            ? data.paymentAccountId
            : null,
      },
      startTimeIncrementInMinutes: data.startTimeIncrementInMinutes,
      accessCode: data.accessCode,
      status: status,
    }
    if (bookingType) {
      updateLessonType({
        variables: {
          input: {
            id: bookingType.id,
            ...input,
          },
        },
      })
    } else {
      createLessonType({
        variables: { input },
      })
    }
  }
  const crumbs = [
    isOnboarding
      ? { label: 'Onboarding Dashboard', to: '/pga-coach' }
      : { label: 'Booking Types', to: '/pga-coach/bookings/booking-types' },
  ]

  return (
    <>
      <PageHeader
        title={bookingType ? 'Edit booking type' : 'Create booking type'}
        crumbs={crumbs}
        progress={progress}
      />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormActionBar
            includeCancel={isEmpty(touched)}
            instructions={hasErrors && 'Form has one or more errors'}
            error={hasErrors}
          >
            {bookingType && !isEmpty(touched) && (
              <Dialog
                trigger={
                  <Button style={{ marginRight: '16px' }}>Cancel</Button>
                }
                maxWidth="xs"
              >
                {({ closeDialog }) => {
                  return (
                    <>
                      <DialogTitle>Unsaved Changes</DialogTitle>
                      <DialogContent>
                        <Typography variant="p" gutterBottom>
                          If you leave before saving, your changes will be lost.
                          Are you sure you want to leave without saving?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary" onClick={closeDialog}>
                          No
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            history.push(
                              isOnboarding
                                ? '/pga-coach'
                                : '/pga-coach/bookings/booking-types',
                            )
                          }
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </>
                  )
                }}
              </Dialog>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-cy="submit"
              disabled={
                isCreateLessonTypeSubmitting || isUpdateLessonTypeSubmitting
              }
            >
              {!bookingType
                ? isLessonPacks
                  ? 'CREATE LESSON PACK'
                  : 'CREATE BOOKING TYPE'
                : 'SAVE'}
            </Button>
          </FormActionBar>
          <Box
            m={isMobile ? 1 : 7}
            mt={isMobile ? 2 : 7}
            style={{ width: 'fit-content' }}
          >
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    title="Booking Details"
                    titleTypographyProps={{ variant: 'h6' }}
                    className={classes.header}
                  />
                  <CardContent className={classes.content}>
                    <Box p={isMobile ? 3 : 4} pb={0}>
                      <SingleOrLessonPack
                        isLessonPacks={isLessonPacks}
                        setIsLessonPacks={setIsLessonPacks}
                        paymentAccounts={paymentAccounts}
                      />
                      <BookingTypeDetails
                        coachFacilities={coachFacilities}
                        coachAvailabilities={coachAvailabilities}
                        coachingCenters={coachingCenters}
                        durationInMinutesOptions={durationInMinutesOptions}
                        categories={categories}
                        handleCategoryChange={handleCategoryChange}
                        startTimeIncrementOptions={startTimeIncrementOptions}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    title="Payment Options"
                    titleTypographyProps={{ variant: 'h6' }}
                    className={classes.header}
                  />
                  <CardContent className={classes.content}>
                    <Box p={isMobile ? 3 : 4} pb={0}>
                      <BookingTypePaymentOptions
                        errors={errors}
                        control={control}
                        register={register}
                        isLessonPacks={isLessonPacks}
                        paymentAccounts={paymentAccounts}
                        singleLessonPrice={singleLessonPrice}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    title="Additional Options"
                    titleTypographyProps={{ variant: 'h6' }}
                    className={classes.header}
                  />
                  <CardContent className={classes.content}>
                    <Box p={isMobile ? 3 : 4} pt={0}>
                      <BookingTypeAdditionalOptions
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        status={status}
                        setStatus={setStatus}
                        bookingType={bookingType}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <BookingTypeFormSuccessDialog
                  isOpen={isSuccessDialogOpen}
                  setIsOpen={setIsSuccessDialogOpen}
                  showOnboardingModal={showOnboardingModal}
                  onClose={onSuccessDialogClose}
                  onCreateAnother={onSuccessDialogCreateAnother}
                  lessonType={lessonTypeSuccessResult ?? {}}
                />
                <Box
                  pt={isMobile ? 2 : 7}
                  pb={isMobile ? 12 : 1}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  {bookingType && (
                    <Dialog
                      trigger={
                        <Button type="button" color="primary" data-cy="delete">
                          <Typography color="error"> Delete </Typography>
                        </Button>
                      }
                      maxWidth="xs"
                    >
                      {({ closeDialog }) => {
                        const onDeleteBookingTypeConfirm = () => {
                          onDeleteBookingType()
                        }
                        return (
                          <>
                            <DialogTitle>Delete Booking Type</DialogTitle>
                            <DialogContent>
                              <Typography variant="p" gutterBottom>
                                Lessons that have already been created will not
                                be impacted.
                              </Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button color="primary" onClick={closeDialog}>
                                CANCEL
                              </Button>
                              <Button
                                data-cy="confirmDelete"
                                color="primary"
                                variant="contained"
                                onClick={onDeleteBookingTypeConfirm}
                              >
                                DELETE
                              </Button>
                            </DialogActions>
                          </>
                        )
                      }}
                    </Dialog>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}

export default BookingTypeForm
