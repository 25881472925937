import { gql } from '@apollo/client'

const GET_GROUP_EVENT_BY_ID = gql`
  query GroupEventById($id: ID) {
    groupEvents(id: $id) {
      enrollments {
        id
        isCanceled
        student {
          id
          firstName
          lastName
          graduations(programId: $id) {
            status
          }
        }
      }
    }
  }
`

const UPSERT_GRADUATE_STATUS = gql`
  mutation UpsertGraduateStatus(
    $status: ProgramGraduateStatus!
    $eventId: ID
    $studentId: ID!
  ) {
    upsertGraduateStatus(
      status: $status
      eventId: $eventId
      studentId: $studentId
    ) {
      success
      message
    }
  }
`

export { GET_GROUP_EVENT_BY_ID, UPSERT_GRADUATE_STATUS }
