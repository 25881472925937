import React, { useState } from 'react'
import get from 'lodash/get'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import emailSpellChecker from '@zootools/email-spell-checker'

const EmailFieldWithSuggestions = ({
  control,
  name,
  errors,
  label,
  classes,
  ...other
}) => {
  const [emailSuggestion, setEmailSuggestion] = useState('')

  const onChangeEmail = value => {
    let email = ''
    email = emailSpellChecker.run({
      email: value || '',
    })
    setEmailSuggestion(email?.full)
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ name, value, ref, onChange }) => (
        <Autocomplete
          name={name}
          filterOptions={(options, state) => options}
          disableClearable
          freeSolo
          value={value}
          inputRef={ref}
          options={emailSuggestion ? [emailSuggestion] : []}
          onChange={(e, value) => onChange(value)}
          renderInput={params => (
            <TextField
              {...params}
              {...other}
              error={!!get(errors, name)}
              helperText={get(errors, name)?.message}
              label={label}
              className={classes.input}
              onChange={e => {
                onChangeEmail(e.target.value)
                onChange(e)
              }}
            />
          )}
        />
      )}
    />
  )
}

export default EmailFieldWithSuggestions
