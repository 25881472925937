import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import Dialog from 'components/dialog'
import { useIsMobile } from 'lib/hooks'
import Attendee from './attendee'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { UPDATE_ENROLLMENT_ATTENDANCES } from '../../query'
import { useSnackbar } from 'notistack'
import LoadingButton from 'components/loading-button'
import { RefetchContext } from 'lib/utils/refetchContext'

const AttendanceModal = ({ trigger, event }) => {
  const enrollments = event.currentSession?.enrollments.filter(
    enrollment => !enrollment.isCanceled,
  )
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useIsMobile()
  const form = useForm({
    defaultValues: {
      enrollments: enrollments,
    },
  })
  const { handleSubmit, control, register, reset } = form
  const { fields } = useFieldArray({
    control,
    name: 'enrollments',
    keyName: '_id',
  })
  const refreshBookings = useContext(RefetchContext)

  const [updateEnrollmentAttendances, { loading }] = useMutation(
    UPDATE_ENROLLMENT_ATTENDANCES,
  )

  const handleSave = closeDialog => formValues => {
    updateEnrollmentAttendances({
      variables: {
        attendances: formValues.enrollments.map(enrollment => ({
          enrollmentId: enrollment.id,
          attended: enrollment.attended === 'true',
        })),
      },
    })
      .then(resp => {
        if (resp.data?.updateEnrollmentAttendances?.success) {
          reset({
            enrollments: resp.data.updateEnrollmentAttendances.enrollments,
          })
        }
        refreshBookings()
        closeDialog()
      })
      .catch(e => {
        enqueueSnackbar('Error saving Attendances', { variant: 'error' })
      })
  }

  return (
    <Dialog trigger={trigger} fullWidth fullScreen={isMobile}>
      {({ closeDialog }) => {
        return (
          <Box py={4} px={3}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              className={classes.backButtonWrapper}
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={() => closeDialog()}
              >
                <ArrowBackIosIcon fontSize="small" />
                <Typography variant="caption" style={{ fontWeight: 900 }}>
                  Back
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                Attendance
              </Typography>
              <Box className={classes.studentContent} width="100%">
                {fields.map((enrollment, index) => (
                  <Attendee
                    enrollment={enrollment}
                    event={event}
                    register={register}
                    index={index}
                  />
                ))}
              </Box>
              <Box display="flex" justifyContent="right" width="100%">
                <Button
                  color="primary"
                  style={{ marginRight: '8px' }}
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleSave(closeDialog))}
                  loading={loading}
                >
                  Update Status
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        )
      }}
    </Dialog>
  )
}

export default AttendanceModal
