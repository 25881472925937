import { useFieldArray, useForm } from 'react-hook-form'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import InitialsAvatar from 'components/avatars/initials-avatar'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import YesNoToggle from './yes-no-toggle'
import CheckIcon from '@material-ui/icons/Check'
import {
  StyledButton,
  ParticipantsWrapper,
  ParticipantsListWrapper,
} from './styles'
import { UPDATE_RSVP } from './query'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: '#EBF0F2',
    color: '#172024',
    width: '40px',
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
  },
}))

const Participants = ({ participants, setResponded, refetch }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({
    defaultValues: {
      participants,
    },
  })
  const { handleSubmit, control, register } = form
  const { fields } = useFieldArray({
    control,
    name: 'participants',
  })
  const [updateRsvp] = useMutation(UPDATE_RSVP)

  const handleSave = formValues => {
    updateRsvp({
      variables: {
        rsvps: formValues.participants.map(participant => ({
          enrollmentId: participant.enrolmentId,
          rsvpStatus: participant.rsvpOnEvent === 'true',
        })),
      },
    })
      .then(() => {
        refetch()
      })
      .catch(e => {
        enqueueSnackbar('Error saving RSVP', { variant: 'error' })
        setResponded(false)
      })
  }

  return (
    <ParticipantsWrapper>
      <ParticipantsListWrapper>
        <List>
          {fields.map(({ id, student, rsvpStatus }, index) => {
            return (
              <ListItem key={id}>
                <ListItemAvatar>
                  <InitialsAvatar
                    name={`${student.firstName} ${student.lastName}`}
                    classes={classes}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={student.firstName + ' ' + student.lastName}
                />
                <input
                  type="hidden"
                  value={id}
                  name={`participants.${index}.enrolmentId`}
                  ref={register()}
                />
                <YesNoToggle
                  rsvp={rsvpStatus}
                  index={index}
                  register={register}
                />
              </ListItem>
            )
          })}
        </List>
      </ParticipantsListWrapper>
      <StyledButton
        variant="contained"
        size="small"
        startIcon={<CheckIcon />}
        onClick={handleSubmit(handleSave)}
      >
        Save
      </StyledButton>
    </ParticipantsWrapper>
  )
}

export default Participants
