import Error from 'components/error'
import { useState } from 'react'
import LeadForm from '../../coach-leads/lead-form'
import SelectTimeLayout from './select-time-layout'
import SelectTimeSkeleton from './select-time-skeleton'
import { useMonthlyLessonAvailability } from './utils'

const SelectLessonTime = ({
  currentUrl,
  previousUrl,
  locationId,
  lessonTypeId,
  lessonCreditBalanceQuantity,
}) => {
  const { data, loading, error, refetch } = useMonthlyLessonAvailability(
    locationId,
    lessonTypeId,
  )
  const [showRequestTime, setShowRequestTime] = useState(false)

  if (error) return <Error error={error} />
  if (loading) return <SelectTimeSkeleton />

  const { lessonTypes } = data
  const coach = lessonTypes[0].coach

  if (showRequestTime) {
    return <LeadForm coach={coach} source="MYPGA_SCHEDULE_FULLY_BOOKED" />
  }

  return (
    <SelectTimeLayout
      lessonTypes={lessonTypes}
      currentUrl={currentUrl}
      previousUrl={previousUrl}
      refetch={refetch}
      setShowRequestTime={coach.coachProfile ? setShowRequestTime : false}
      lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
    />
  )
}

export default SelectLessonTime
