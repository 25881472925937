import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { LinearProgress } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import Error from 'components/error'
import { ContainerRoot } from 'pages/schedule/components/styles'
import BackButton from 'components/back-button'
import { refreshedCategories } from 'components/lesson-type-category/utils'
import CoachAppBar from 'components/coach-app-bar'
import LessonPackSelection from '../components/lesson-pack-selection'
import qs from 'query-string'
import { get } from 'lodash'
import LessonTypesByCoach from 'pages/academy/select-coach/index'
import { useGetAvailableTimesByDay } from 'pages/academy/query'
import SelectStudentPage from '../components/select-student-page'
import { withAuthenticatedRoute } from 'lib/auth'
import SelectTimeSlotLayout from '../components/select-time-slot-layout'
import RedeemLessonsCredit from 'pages/schedule/RedeemLessonsCredit'
import GET_LESSON_CREDIT_BALANCE from 'pages/club/my-lesson-packs/query'
import {
  COACHING_CENTER_SLUGS,
  FRISCO_COACHING_CENTER_SLUG,
} from 'utils/constants'
import NotFound from 'components/not-found.js'

const getCurrentUrl = () => {
  return window.location.pathname + window.location.search
}

export const AcademyScheduleStepController = () => {
  const location = useLocation()
  const currentUrl = getCurrentUrl()
  // TODO: this is a hack to get the academy slug from the frisco coaching-center
  const pgaFriscoSlug = location.pathname
    .toLowerCase()
    .includes(FRISCO_COACHING_CENTER_SLUG)
    ? FRISCO_COACHING_CENTER_SLUG
    : null
  const slug =
    currentUrl.includes('/coaching-center/') &&
    currentUrl.split('/coaching-center/')[1].split('/')[0]
  // matches params from the routes, i.e. /coaching-center/:slug/schedule
  const academySlug = pgaFriscoSlug || slug
  const {
    startDateTime,
    lessonTypeId,
    studentId,
    returnTo: redirectUrl,
    category,
    facilityId,
  } = qs.parse(location.search)
  const lessonCategory = category?.toUpperCase()
  // How does this work if currentUser is a coach? How does it work if they are not logged in?
  const { data: lessonCreditData } = useQuery(GET_LESSON_CREDIT_BALANCE)
  const lessonCreditBalances = useMemo(
    () =>
      lessonCreditData &&
      get(lessonCreditData, 'currentUser.attributes.lessonPacks'),
    [lessonCreditData],
  )
  const applicableLessonCredits = useMemo(() => {
    return (
      lessonCreditBalances?.length > 0 &&
      lessonCreditBalances.filter(
        lessonPack =>
          lessonPack.remainingCredits > 0 &&
          lessonPack.lessonType.id === lessonTypeId,
      )
    )
  }, [lessonCreditBalances, lessonTypeId])
  // IF lesson type id THEN check for the existing lesson packofferings

  const lessonCreditBalanceQuantity =
    applicableLessonCredits[0]?.remainingCredits || 0

  const selectedCategory = refreshedCategories[lessonCategory]
  const lessonTypeCategory = {
    ...selectedCategory,
    categoryParam: lessonCategory,
  }
  const shouldSkip = !selectedCategory
  const { data, loading, error, refetch } = useGetAvailableTimesByDay(
    academySlug,
    lessonCategory,
    shouldSkip,
  )

  if (loading) return <LinearProgress />
  if (error || !selectedCategory) {
    return (
      <NotFound returnUrl={`${FRISCO_COACHING_CENTER_SLUG}?category=PRIVATE`} />
    )
  }

  const academy = data?.academy

  const coaches = academy.availableTimes
    .filter(t => t.startDateTime === startDateTime)?.[0]
    ?.coaches?.filter(
      (coach, index, self) =>
        index ===
        self.findIndex(c => c.id === coach.id && c.name === coach.name),
    )

  if (!academy) return <Error statusCode={404} />

  // if the academySlug is for the Frisco Coaching Center then we want to change the url as a variable
  const academySlugForUrl = COACHING_CENTER_SLUGS.includes(academySlug)
    ? `${academySlug}`
    : `/coaching-center/${academySlug}`

  // student has valid lesson credits for this lesson type
  if (
    academySlug &&
    startDateTime &&
    lessonTypeId &&
    studentId &&
    facilityId &&
    lessonCreditBalanceQuantity // Step to redeem Lesson Pack credits
  ) {
    const previousUrl = `${academySlugForUrl}?category=${lessonCategory}&startDateTime=${startDateTime}&lessonTypeId=${lessonTypeId}&facilityId=${facilityId}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`
    return (
      <>
        <CoachAppBar position="relative" hideContactButton />
        <RedeemLessonsCredit
          idOrSlug={academySlug}
          lessonTypeId={lessonTypeId}
          locationId={academy?.facilities[0].id}
          startDateTime={startDateTime}
          studentId={studentId}
          previousUrl={previousUrl}
          lessonCreditBalanceQuantity={lessonCreditBalanceQuantity.toString()}
          progressValue={75}
          stepText="Step 4 of 4"
        />
      </>
    )
  }

  // student does not have valid lesson credits for this lesson type. Show lesson pack selection and purchase
  // OR show lesson purchase if no lesson packs available
  if (academySlug && startDateTime && lessonTypeId && facilityId && studentId) {
    const previousUrl = `${academySlugForUrl}?category=${lessonCategory}&startDateTime=${startDateTime}&lessonTypeId=${lessonTypeId}&facilityId=${facilityId}`
    return (
      <LessonPackSelection
        idOrSlug={academySlug}
        lessonTypeId={lessonTypeId}
        locationId={facilityId}
        startDateTime={startDateTime}
        studentId={studentId}
        previousUrl={previousUrl}
        progressValue={75}
        stepText="Step 4 of 4"
      />
    )
  }

  // select student to take the lesson
  if (academySlug && startDateTime && lessonTypeId) {
    const previousUrl = `${academySlugForUrl}?category=${lessonCategory}&startDateTime=${startDateTime}`
    return (
      <>
        <CoachAppBar position="relative" hideContactButton />
        <ContainerRoot>
          <BackButton previousUrl={previousUrl} />
          <SelectStudentPage
            academy={academy}
            category={lessonTypeCategory}
            lessonTypeId={lessonTypeId}
            currentUrl={currentUrl}
            previousUrl={previousUrl}
            progressValue={75}
            stepText="Step 3 of 4"
          />
        </ContainerRoot>
      </>
    )
  }

  // choose the lesson type and coach
  if (academySlug && startDateTime) {
    const previousUrl = `${academySlugForUrl}?category=${lessonCategory}`
    return (
      <>
        <CoachAppBar position="relative" hideContactButton />
        <LessonTypesByCoach
          academy={academy}
          lessonTypeCategory={lessonTypeCategory}
          coaches={coaches}
          currentUrl={currentUrl}
          previousUrl={previousUrl}
        />
      </>
    )
  }

  // choose the lesson date and time
  return (
    <SelectTimeSlotLayout
      academy={academy}
      lessonTypeCategory={lessonTypeCategory}
      refetch={refetch}
      redirectUrl={redirectUrl}
    />
  )
}

export default withAuthenticatedRoute(AcademyScheduleStepController)
