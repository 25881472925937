import React from 'react'
import Box from '@material-ui/core/Box'
import { Link as LinkTo, useRouteMatch } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import YesNoToggle from './yes-no-toggle'
import { useStyles } from './styles'
import { getInitials } from 'lib/text-display'

const HopeLinkWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

const Attendee = ({ enrollment, event, register, index }) => {
  const { slug, tags } = event
  const { attended, id, student } = enrollment
  const { path } = useRouteMatch()
  const classes = useStyles()

  const isHopeEvent = tags.includes('PGA HOPE')
  const studentName = `${student.firstName} ${student.lastName}`
  const studentInitials = getInitials(studentName)
  const URL = `${path
    .split('/')
    .slice(0, 2)
    .join('/')}/${slug}`

  return (
    <Box key={id} display="flex" alignItems="center" mb={2}>
      <HopeLinkWrapper
        condition={isHopeEvent}
        wrapper={children => (
          <LinkTo
            to={`${URL}/${student.id}`}
            style={{
              textDecoration: 'none',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {children}
          </LinkTo>
        )}
      >
        <Avatar className={classes.avatarWrapper}>{studentInitials}</Avatar>
      </HopeLinkWrapper>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Box>
          <HopeLinkWrapper
            condition={isHopeEvent}
            wrapper={children => (
              <LinkTo
                to={`${URL}/${student.id}`}
                style={{
                  textDecoration: 'none',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                {children}
              </LinkTo>
            )}
          >
            <Typography variant="body1">{studentName}</Typography>
          </HopeLinkWrapper>
        </Box>
        <YesNoToggle attended={attended} index={index} register={register} />
      </Box>
      <input
        type="hidden"
        value={id}
        name={`enrollments.${index}.id`}
        ref={register()}
      />
    </Box>
  )
}

export default Attendee
