import React from 'react'
import { Redirect } from 'react-router-dom'
import { roles, useAuth } from 'lib/auth'

export default function withFamilyCupAccess(Component) {
  return function WithFamilyCupAccess(props) {
    const { user } = useAuth()
    const isAdmin = user?.programAdmin || user?.hasRole([roles.SECTION_STAFF])

    if (!user?.familyCupSignedUp && !isAdmin) {
      return <Redirect to={'/family-golf-signup?signupRequired=true'} />
    }

    return <Component {...props} />
  }
}
