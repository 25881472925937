import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import {
  EmailFieldWithSuggestions,
  PhoneField,
  TextFieldWithError,
} from 'components/form-util'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import useStyles from './group-event-registration-form.styles'
import { genderOptions } from 'lib/form-schemas/common'
import Grid from '@material-ui/core/Grid'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import PgaKeyboardDatePicker from 'components/pga-time-picker/pga-keyboard-date-picker'

const ParticipantInformation = () => {
  const { control, register, errors } = useFormContext()

  const classes = useStyles()

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '16px' }}>
        Participant Information
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginBottom: '16px' }}
      >
        *Indicates required fields
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <TextField
              name="student.firstName"
              inputRef={register}
              error={!!errors?.student?.firstName}
              helperText={errors?.student?.firstName?.message}
              label="First name"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              name="student.lastName"
              inputRef={register}
              error={!!errors?.student?.lastName}
              helperText={errors?.student?.lastName?.message}
              label="Last name"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <EmailFieldWithSuggestions
              name="student.email"
              control={control}
              errors={errors}
              label="Email"
              classes={classes}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneField
              name="student.phone"
              inputRef={register}
              error={!!errors?.student?.phone}
              helperText={errors?.student?.phone?.message}
              label="Phone Number"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl className={classes.input}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  as={
                    <TextFieldWithError
                      component={PgaKeyboardDatePicker}
                      required
                      disableFuture
                      label="Date of Birth *"
                      format="MM/dd/yyyy"
                      errors={errors}
                      name="student.dateOfBirth"
                    />
                  }
                  control={control}
                  name="student.dateOfBirth"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl
              error={!!errors?.student?.gender}
              variant="outlined"
              className={classes.input}
              required
            >
              <InputLabel id="pga-hope-golf-history-label">Gender</InputLabel>
              <Controller
                as={
                  <Select
                    labelId="pga-hope-golf-history-label"
                    label="Gender"
                    value=""
                  >
                    {genderOptions.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="student.gender"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="student.zipCode"
              inputRef={register}
              error={!!errors?.student?.zipCode}
              helperText={errors?.student?.zipCode?.message}
              label="Zip Code"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ParticipantInformation
