import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import PGALogo from 'images/pga-seal-icon.svg'
import PgaHopeLogo from 'images/pga-hope-logo.svg'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { formattedTimeWithTimezone } from 'utils/dateUtils'
import Participants from './participants'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { GET_GROUP_EVENT } from './query'
import { LinearProgress } from '@material-ui/core'
import Error from 'components/error'
import Actions from './actions'
import useStyles, {
  RootPage,
  StyledTitle,
  StyledDescription,
  StyledEventTitle,
  StyledCard,
  StyledLogo,
  UpdateRsvpButton,
  StyledCheckIcon,
  StyledCancelIcon,
  StyledCardMedia,
  LogoWrapper,
} from './styles'

const AttendeeRsvp = () => {
  const { eventId, lessonId, contactId } = useParams()

  const CenteredIcon = ({ Icon }) => (
    <Box margin="auto auto">
      <Icon fontSize="large" />
    </Box>
  )

  const WrappedStyledCardMedia = ({ background, children }) => (
    <StyledCardMedia background={background}>{children}</StyledCardMedia>
  )

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))
  const classes = useStyles()
  const [isResponded, setIsResponded] = useState(false)
  const [hasYesRespond, setHasYesRespond] = useState(false)
  const { data, loading, error, refetch } = useQuery(GET_GROUP_EVENT, {
    variables: { id: eventId, lessonId: lessonId, contactId: contactId },
  })
  const groupEvent = data?.groupEvents[0]
  const groupEventAttendeesByLesson = data?.groupEventAttendeesByLesson
  const isPgaHopeEvent = groupEvent?.tags.includes('PGA HOPE')
  const logo = isPgaHopeEvent ? PgaHopeLogo : PGALogo
  const logoWidthMobile = isPgaHopeEvent ? '60px' : '40px'
  const logoAltText = isPgaHopeEvent ? 'PGA HOPE Logo' : 'PGA Logo'

  useEffect(() => {
    if (groupEvent) {
      const currentEventEnrollments = groupEventAttendeesByLesson.enrollments
      const hasResponded = currentEventEnrollments.some(
        enrollment => enrollment.rsvpStatus !== null,
      )
      setHasYesRespond(
        currentEventEnrollments.some(enrollment => enrollment.rsvpStatus),
      )
      setIsResponded(hasResponded)
    }
  }, [groupEvent, groupEventAttendeesByLesson])

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const { featuredImageUrl, coach, coachFacility } = groupEvent
  return (
    <RootPage isPgaHopeEvent={isPgaHopeEvent}>
      <Dialog
        fullScreen={isMobile}
        isOpen={true}
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
      >
        {() => {
          return (
            <>
              <LogoWrapper>
                <img
                  src={logo}
                  width={logoWidthMobile}
                  height="40px"
                  alt={logoAltText}
                />
              </LogoWrapper>
              <Box
                className={clsx(
                  classes.root,
                  isPgaHopeEvent && classes.hopeRoot,
                )}
              >
                <StyledLogo src={logo} alt={logoAltText} />
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign={isMobile ? 'left' : 'center'}
                >
                  <StyledTitle variant="h4" color="primary">
                    {isResponded ? 'Thank you!' : 'Response Requested'}
                  </StyledTitle>
                  <StyledDescription>
                    {isResponded
                      ? "You're all set! Your coach has been notified of your attendance. If anything changes, use the same link to update your RSVP."
                      : 'RSVP to let your coach know if you are able to attend'}
                  </StyledDescription>
                </Box>
                <StyledCard elevation={2}>
                  {isResponded ? (
                    hasYesRespond ? (
                      <WrappedStyledCardMedia
                        background={`linear-gradient(0deg, rgba(0, 32, 91, 0.70) 0%, rgba(0, 32, 91, 0.70) 100%), url(${featuredImageUrl}) lightgray 50% / cover no-repeat;`}
                      >
                        <CenteredIcon Icon={StyledCheckIcon} />
                      </WrappedStyledCardMedia>
                    ) : (
                      <WrappedStyledCardMedia
                        background={`linear-gradient(0deg, rgba(185, 29, 28, 0.60) 0%, rgba(185, 29, 28, 0.60) 100%), url(${featuredImageUrl}) lightgray 50% / cover no-repeat;`}
                      >
                        <CenteredIcon Icon={StyledCancelIcon} />
                      </WrappedStyledCardMedia>
                    )
                  ) : (
                    <StyledCardMedia
                      background={`url(${featuredImageUrl}) no-repeat center/cover`}
                    />
                  )}
                  <CardContent>
                    <StyledEventTitle>{groupEvent.title}</StyledEventTitle>
                    <Typography variant="body2">
                      {coachFacility.name}
                    </Typography>
                    <Typography variant="body2">
                      {formattedTimeWithTimezone(
                        groupEvent.startDateTime,
                        groupEvent.endDateTime,
                        groupEvent.timezone,
                      )}
                    </Typography>
                    <Typography variant="body2">
                      {isResponded ? (
                        <UpdateRsvpButton
                          onClick={() => setIsResponded(!isResponded)}
                        >
                          Update RSVP
                        </UpdateRsvpButton>
                      ) : (
                        coach.name
                      )}
                    </Typography>
                  </CardContent>
                </StyledCard>
                {isResponded ? (
                  <Actions groupEvent={groupEvent} />
                ) : (
                  <Participants
                    participants={groupEventAttendeesByLesson.enrollments}
                    setResponded={setIsResponded}
                    refetch={refetch}
                  />
                )}
              </Box>
            </>
          )
        }}
      </Dialog>
    </RootPage>
  )
}

export default AttendeeRsvp
