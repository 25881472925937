import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import { YouTube } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { XIcon } from 'components/x-share-button/x-icon'
import ButtonCta from 'components/atoms/button-cta'
const CoachLargeContactBanner = ({ coach, handleGetInTouchClick }) => {
  const classes = useStyles()
  const coachFirstName = coach.name.split(' ')[0]
  const coachSocials = coach.coachProfile.socialJsonData
  const socialJsonData = [
    {
      type: 'IG',
      icon: <InstagramIcon fontSize="large" />,
    },
    {
      type: 'FB',
      icon: <FacebookIcon fontSize="large" />,
    },
    {
      type: 'YT',
      icon: <YouTube fontSize="large" />,
    },
    {
      type: 'TWT',
      icon: <XIcon fontSize="large" />,
    },
  ]
  const SocialIcon = ({ social }) => {
    const { type, reference } = social
    return (
      <IconButton
        href={reference}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.socialIcon}
      >
        {socialJsonData.find(social => social.type === type).icon}
      </IconButton>
    )
  }
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      data-testid="social-banner"
      className={classes.journeyContainer}
    >
      <Grid item xs={12} md={3} className={classes.fullHeroText}>
        <Typography variant="h4">Begin Your Journey</Typography>
      </Grid>
      <Grid
        item
        container
        className={classes.coachContactsSection}
        sm={9}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container item xs={12} display="flex" direction="column">
            <Typography
              variant="h6"
              className={classes.title}
            >{`Work with ${coachFirstName}`}</Typography>
            <Typography variant="caption" className={classes.caption}>
              Send a message regarding your goals.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonBox}>
            <ButtonCta
              size="small"
              variant="contained"
              color="primary"
              data-testid="consolidated-banner-contact-button"
              href={`/coach/${coach.coachProfile.slug}/contact?source=mypga_schedule_contact_button`}
            >
              Contact {coachFirstName}
            </ButtonCta>
          </Grid>
        </Grid>
        {coachSocials && coachSocials.length > 0 && (
          <Grid item>
            <Grid container item xs={12} display="flex" direction="column">
              <Typography variant="h6" className={classes.title}>
                Social Media
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                Follow us on social media
              </Typography>
              <Grid container item className={classes.buttonBox}>
                <Box className={classes.socialMediaSection}>
                  {coachSocials.map((social, index) => {
                    return <SocialIcon key={index} social={social} />
                  })}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default CoachLargeContactBanner
