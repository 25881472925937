import React from 'react'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useFieldArray, useForm } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import StudentRow from './student-row'
import Button from '@material-ui/core/Button'
import LoadingButton from 'components/loading-button'
import { UPSERT_GRADUATE_STATUS } from './queries'

const GraduateForm = ({ event, enrollments, closeDialog, refetch }) => {
  const activeEnrollments = enrollments.filter(e => !e.isCanceled)
  const students = activeEnrollments.map(e => e.student)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(false)
  const form = useForm({
    defaultValues: {
      students: students,
    },
  })
  const { handleSubmit, control, register } = form
  const { fields } = useFieldArray({
    control,
    name: 'students',
  })

  const [upsertGraduateStatus] = useMutation(UPSERT_GRADUATE_STATUS)

  const handleSave = formValues => {
    setLoading(true)
    Promise.all(
      formValues.students.map(student =>
        upsertGraduateStatus({
          variables: {
            status: student.status,
            eventId: event.id,
            studentId: student.id,
          },
        }),
      ),
    )
      .then(resp => {
        const isSuccess = resp.every(r => r.data?.upsertGraduateStatus?.success)
        refetch()
        if (isSuccess) {
          enqueueSnackbar('Graduate Status updated successfully', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar('Error updating Graduate Status', {
            variant: 'error',
          })
        }
      })
      .catch(e => {
        enqueueSnackbar('Error updating Graduate Status', { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {fields.map((student, index) => (
        <StudentRow
          student={student}
          sessions={event.sessions}
          key={student.id}
          register={register}
          index={index}
          eventId={event.id}
        />
      ))}
      <Box display="flex" justifyContent="right" width="100%" mt={3} mb={1}>
        <Button
          color="primary"
          style={{ marginRight: '8px' }}
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSave)}
          loading={loading}
        >
          Update Status
        </LoadingButton>
      </Box>
    </>
  )
}

export default GraduateForm
