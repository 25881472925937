export const FEATURE_FLAG_EXPERIMENT_COOKIE_NAME = 'x-flag-exp'
export const FEATURE_FLAG_INTERNAL_COOKIE_NAME = 'x-flag-internal'

export const flags = {
  FLAG_EXPEDIED_SHIPPING_DISABLED: 'my-pga-com-jrl-expedited-shipping-disabled',
  FLAG_FEAT_GROUP_EVENT_RECURRING_PAYMENTS_ENABLED:
    'my-pga-com-feat-group-event-recurring-payments-enabled',
  FLAG_STUDENT_REVENUE_STAT: 'my-pga-com-student-revenue-statistic',
  FLAG_REFUND_QUICK_ACTION: 'my-pga-com-refund-quick-action',
  FLAG_APP_INSTALL_BANNER_AD: 'my-pga-com-app-install-banner-ad',
  FLAG_FEAT_COACHING_CENTER_STUDENT_LIST_MENU_MANAGEMENT:
    'my-pga-com-student-list-management-visibility',
  FLAG_FEAT_HOME_AWAY_EVENTS: 'my-pga-com-home-away-events',
  FLAG_FEAT_ENFORCE_FCC: 'my-pga-com-enforce-fcc-preselect',
  FLAG_FG_EVENT: 'my-pga-com-fg-event',
  FLAG_FEAT_BYPASS_JRL_REG: 'my-pga-com-jrl-registration-date-bypass',
  FLAG_FEAT_STREAM_THREADS: 'mobile-and-my-pga-com-stream-threads',
  FLAG_FEAT_FUNDAMENTAL_MOVEMENT_SKILLS:
    'my-pga-com-fundamental-movement-skills',
  FLAG_FEAT_STUDENT_CANCELLATION: 'my-pga-com-and-mobile-student-cancellation',
  FLAG_FEAT_MARKETING_OPT_IN_EXP: 'my-pga-com-exp-marketing-opt-in',
  FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS:
    'my-pga-com-and-mobile-age-specific-group-events',
  FLAG_FEAT_MARKETING_OPT_IN_LESSON_PACKS:
    'my-pga-com-and-mobile-marketing-opt-in-lesson-packs',
  FLAG_FEAT_MARKETING_OPT_IN_GROUP_EVENTS:
    'my-pga-com-and-mobile-marketing-opt-in-group-events',
  FLAG_FEAT_MARKETING_OPT_IN_PRIVATE_BOOKINGS:
    'my-pga-com-and-mobile-marketing-opt-in-private-bookings',
  FLAG_FEAT_GROUP_EVENT_RSVP: 'my-pga-com-group-event-rsvp',
  FLAG_FEAT_HOPE_GRADUATE: 'my-pga-com-hope-graduate',
}
