import { formatPhoneNumber } from 'utils/phoneNumber'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import HelpIcon from '@material-ui/icons/Help'

export const columnHeaders = [
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Canceled Registration',
  'Date Registered',
]
export const golferProfileHeaders = [
  'Frequency of Play',
  'Home Golf Course',
  'Golf Journey',
  'Has Own Clubs',
  'Has Been Fitted',
  'Handedness (L/R)',
  'Handicap',
  'Confident Area',
  'Improvement Area',
  'Gender',
  'Pronouns',
  'Physical Limitations',
]
export const hopeColumnHeaders = [
  'Gender',
  'Accommodations',
  'Additional Info',
  'Golf Career Interest',
  'Golf Experience',
  'Handedness (L/R)',
  'Has Own Clubs',
  'Military Status',
  'Referral Source',
  'Service Branch',
  'Service Year Start',
  'Service Year End',
  'Shirt Size',
  'Emergency Contact First Name',
  'Emergency Contact Last Name',
  'Emergency Contact Phone',
  'Emergency Contact Relationship',
]

export const waitlistColumnHeaders = ['First Name', 'Last Name', 'Email']

const defineColumnHeaders = ({ isHopeEvent, isWaitlist }) => {
  if (isWaitlist) {
    return waitlistColumnHeaders
  } else {
    const standardColumnHeaders = [...columnHeaders, ...golferProfileHeaders]

    const pgaHopeColumnHeaders = [...columnHeaders, ...hopeColumnHeaders]

    return isHopeEvent ? pgaHopeColumnHeaders : standardColumnHeaders
  }
}

const defineAttendeesData = (
  enrollments,
  waitlist,
  isHopeEvent = false,
  isWaitlist = false,
  isCanceledAttendees = false,
) => {
  if (isWaitlist) {
    return waitlist.map(w => {
      const { contact } = w
      return [contact.firstName, contact.lastName, contact.email]
    })
  }
  const canceledEnrollments = enrollments.filter(e => e.isCanceled)
  const activeEnrollments = enrollments.filter(e => !e.isCanceled)
  const selectedEnrollments = isCanceledAttendees
    ? canceledEnrollments
    : activeEnrollments

  return selectedEnrollments.map(e => {
    const { student } = e
    const email = student.representatives.find(r => r.contact.email != null)
      ?.contact.email
    const phone = student.representatives.find(r => r.contact.phone != null)
      ?.contact.phone
    const pgaHopeProfile = student.pgaHopeProfile
    const studentDemographics = student.demographicProfile
    const pgaHopeEmergencyContact = student.pgaHopeEmergencyContact
    const golferProfile = student.golferProfile
    const golferProfileData = [
      golferProfile?.frequencyOfPlay || '',
      golferProfile?.homeGolfCourse || '',
      golferProfile?.golfJourney || '',
      golferProfile?.hasOwnClubs || 'FALSE',
      golferProfile?.hasBeenFitted || 'FALSE',
      golferProfile?.handedness || '',
      golferProfile?.handicap || '',
      golferProfile?.confidentArea || '',
      golferProfile?.improvementArea || '',
      golferProfile?.genderSelfDescription || golferProfile?.gender || '',
      golferProfile?.pronouns || '',
      golferProfile?.physicalLimitations || '',
    ]
    const pgaHopeProfileData = [
      studentDemographics?.gender || golferProfile?.gender || '',
      pgaHopeProfile?.accommodations ||
        golferProfile?.physicalLimitations ||
        '',
      pgaHopeProfile?.additionalInfo || '',
      pgaHopeProfile?.golfCareerInterest || 'FALSE',
      pgaHopeProfile?.golfExperience || golferProfile?.golfJourney || '',
      pgaHopeProfile?.handedness || golferProfile?.handedness || '',
      pgaHopeProfile?.hasOwnClubs || golferProfile?.hasOwnClubs || 'FALSE',
      pgaHopeProfile?.militaryStatus || '',
      pgaHopeProfile?.referralSource || '',
      pgaHopeProfile?.serviceBranch || '',
      pgaHopeProfile?.serviceYearStart || '',
      pgaHopeProfile?.serviceYearEnd || '',
      pgaHopeProfile?.shirtSize || '',
      pgaHopeEmergencyContact?.firstName || '',
      pgaHopeEmergencyContact?.lastName || '',
      formatPhoneNumber(pgaHopeEmergencyContact?.phone),
      pgaHopeEmergencyContact?.relationship || '',
    ]

    return [
      student.firstName,
      student.lastName,
      email || '',
      formatPhoneNumber(phone) || '',
      e.isCanceled || 'FALSE',
      e.createdAt,
      ...(isHopeEvent ? pgaHopeProfileData : golferProfileData),
    ]
  })
}

const generateParticipantCsvData = (
  enrollments,
  waitlist = [],
  isHopeEvent = false,
  isWaitlist = false,
  isCanceledAttendees = false,
) => {
  const csvHeaders = defineColumnHeaders({
    isHopeEvent,
    isWaitlist,
  })

  return [
    csvHeaders,
    ...defineAttendeesData(
      enrollments,
      waitlist,
      isHopeEvent,
      isWaitlist,
      isCanceledAttendees,
    ),
  ]
}

const getIconByRsvpStatus = rsvpStatus => {
  switch (rsvpStatus) {
    case true:
      return <CheckCircleIcon style={{ color: '#3268A7' }} />
    case false:
      return <CancelIcon style={{ color: '#F44336' }} />
    case null:
      return <HelpIcon style={{ color: '#616161' }} />
    default:
      return null
  }
}

const getRsvpStatus = (enrollment, event) => {
  if (!event?.collectAttendance) return null
  const sessionEnrollment = event?.currentSession?.enrollments.find(
    e => e.student.id === enrollment.student.id,
  )
  const showAttendanceStatus =
    new Date(event?.currentSession?.startDateTime) < new Date()
  return getIconByRsvpStatus(
    showAttendanceStatus ? sessionEnrollment?.attended : enrollment?.rsvpStatus,
  )
}

export { generateParticipantCsvData, getRsvpStatus, getIconByRsvpStatus }
